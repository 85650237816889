/* Custom uiKit grid */
/* 8 column grid */
.uk-width-large-1-8,
.uk-width-medium-1-8,
.uk-width-small-1-8,
.uk-width-1-8 {width: 12.5%}
.uk-width-large-2-8,
.uk-width-medium-2-8,
.uk-width-small-2-8,
.uk-width-2-8 {width: 25%}
.uk-width-large-3-8,
.uk-width-medium-3-8,
.uk-width-small-3-8,
.uk-width-3-8 {width: 37.5%}
.uk-width-large-4-8,
.uk-width-medium-4-8,
.uk-width-small-4-8,
.uk-width-4-8 {width: 50%}
.uk-width-large-5-8,
.uk-width-medium-5-8,
.uk-width-small-5-8,
.uk-width-5-8 {width: 62.5%}
.uk-width-large-6-8,
.uk-width-medium-6-8,
.uk-width-small-6-8,
.uk-width-6-8 {width: 75%}
.uk-width-large-7-8,
.uk-width-medium-7-8,
.uk-width-small-7-8,
.uk-width-7-8 {width: 87.5%}
.uk-width-large-8-8,
.uk-width-medium-8-8,
.uk-width-small-8-8,
.uk-width-8-8 {width: 100%}