/*
      ___          ___          ___          ___
     /  /\        /  /\        /  /\        /  /\     ___
    /  /::\      /  /::\      /  /::|      /  /::\   /__/\
   /  /:/\:\    /  /:/\:\    /  /:|:|     /  /:/\:\  \  \:\
  /  /::\ \:\  /  /:/  \:\  /  /:/|:|__  /  /::\ \:\  \__\:\
 /__/:/\:\_\:\/__/:/ \__\:|/__/:/ |:| /\/__/:/\:\ \:\ /  /::\
 \__\/  \:\/:/\  \:\ /  /:/\__\/  |:|/:/\  \:\ \:\_\//  /:/\:\
      \__\::/  \  \:\  /:/     |  |:/:/  \  \:\ \:\ /  /:/__\/
      /  /:/    \  \:\/:/      |__|::/    \  \:\_\//__/:/
     /__/:/      \__\::/       /__/:/      \  \:\  \__\/
     \__\/           ~~        \__\/        \__\/
*
* by Rudy Affandi (2015)
* Adnet Communications Inc.
* app.sass
*/

html {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

body {
    min-height: 100%;
}


/* CP */

.cp {}

.cp.login {}

.cp.login.bg {
    background: url('../img/cp-bg-blur.jpg') no-repeat 50% 50%;
    background-size: cover
}


/* Branding */

#adnet-logo3 {
    fill: rgba(0, 0, 0, 0.1);
}

.uk-navbar-brand h2 {
    margin: 0;
    line-height: 41px;
}

.position-fixed {
    position: fixed;
    z-index: 10;
    width: 100%;
}


/* Status */

.status {}

.status.good {
    color: #0c0;
}

.status.bad {
    color: #c00;
}

a {
    transition: all .2s ease-in-out;
    color: #34B0EA;
}

a:hover {
    text-decoration: none;
}

.uk-active > a {
    color: #f90 !important;
}

.dark-theme a {
    color: #188CB7;
}

.dark-theme a:hover {
    color: #fff;
}

.brand {
    font-weight: 300;
}

.uk-navbar-brand h2 span.brand-title {
    font-size: 18px;
}

.uk-navbar-brand h2 span.brand-subtitle {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.4);
}

.padding {
    padding: 20px;
}

.dark-theme {
    background-color: #33393E;
}

.light-theme {
    background-color: #f0f0f0;
}

.dark-theme {
    color: #858B92;
}

.dark-theme h2 {
    color: #eee;
    font-family: 'Roboto', sans-serif;
}

.light-theme h2 {
    font-family: 'Roboto', sans-serif;
}

.dark-theme h1,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6 {
    color: #34B0EA;
    font-family: 'Roboto', sans-serif;
}

.light-theme h1,
.light-theme h3,
.light-theme h4,
.light-theme h5,
.light-theme h6 {
    color: #34B0EA;
    font-family: 'Roboto', sans-serif;
    text-shadow: 1px 1px 1px #fff;
}

.dark-theme .uk-panel-box {
    background: #3B4248;
    border-color: #262A2E;
}

.dark-theme .uk-table td,
.dark-theme .uk-table th,
.dark-theme .uk-description-list-line>dt:nth-child(n+2) {
    border-color: #262A2E;
}

.dark-theme .uk-button.uk-button-flat {
    background: #353C41;
    color: #858B92;
    text-shadow: none;
}

.dark-theme .uk-button.uk-button-flat:hover {
    background: #494f54;
    color: #d0d0d0;
    text-shadow: none;
}

.dark-theme .uk-accordion-title {
    background: url('../img/accordion-up-dark-theme.png') no-repeat 100% 8px;
    border: 0;
}

.dark-theme .uk-accordion-title.uk-active {
    background: url('../img/accordion-down-dark-theme.png') no-repeat 100% 12px;
    border: 0;
}

.dark-theme .uk-search-field {
    color: #eaeaea;
    background: #3B4248;
    border-radius: 4px;
}

.light-theme .uk-accordion-title {
    background: url('../img/accordion-up-light-theme.png') no-repeat 100% 8px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ddd;
}

.light-theme .uk-accordion-title.uk-active {
    background: url('../img/accordion-down-light-theme.png') no-repeat 100% 12px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ddd;
}

.uk-panel-box:hover {
    background: #fff;
}


/* Sidebar */

.sidebar {
    background: #000;
    width: 250px;
    height: 100%;
    overflow: auto;
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    transition: all .2s ease-in-out;
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.4)
}

.sidebar a span {
    margin-left: 10px;
    transition: none;
}

.sidebar.uk-collapsed {
    width: 50px;
    overflow: visible;
}

.sidebar.uk-collapsed a span {
    display: none;
}

.sidebar.uk-collapsed .sidebar-toggle {
    display: block;
    text-align: center;
    color: #fff;
    padding: 0;
}

.sidebar .sidebar-toggle {
    display: block;
    text-align: right;
    color: #fff;
    padding-right: 10px;
}

.sidebar-toggle:hover {
    color: #34B0EA;
}


/* Icon nav */

.sidebar .nav {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar .nav > li {
    text-align: left;
    margin: 10px 0;
    padding: 10px 0 20px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
}

.sidebar .nav > li > a {
    display: block;
    padding: 0;
}

.sidebar .nav > li > a > i {
    width: 30px;
    text-align: center;
}

.sidebar .nav .uk-nav-header {
    display: none;
}

.sidebar.uk-collapsed .nav > li {
    display: block;
    text-align: center;
    margin: 10px 0;
    padding: 10px 0 20px;
}

.sidebar .nav a {
    display: block;
    color: #fff;
}

.sidebar .nav a:hover {
    display: block;
    color: #34B0EA;
}


/* Sub nav */

.sidebar .uk-nav-sub {
    margin-left: 30px;
    text-align: left;
}

.sidebar .uk-nav-sub li {
    margin-bottom: 5px;
}

.sidebar .uk-nav-sub > li a[class^="uk-icon"]:before {
    width: 20px;
    text-align: center;
}


/* Hide subnav when sidebar is collapsed */

.sidebar.uk-collapsed .uk-nav-sub {
    display: none;
    visibility: hidden;
    opacity: 0;
}


/* Sub nav becomes side nav when sidebar is collapsed */
.sidebar.uk-collapsed .nav > li:hover div {
    display: block !important;
    visibility: visible !important;
    opacity: 1;
    transition-delay: 0s;
    position: absolute !important;
    left: 40px;
    top: 0;
    width: 200px;
    margin: 0;
    padding: 0;
    overflow: visible !important;
}

.sidebar.uk-collapsed .nav > li:hover div ul {
    display: block !important;
    visibility: visible !important;
    opacity: 1;
    transition-delay: 0s;
    background-color: rgba(0, 0, 0, 0.9);
    width: 200px;
    margin: 0;
    padding: 10px 10px 20px 10px;
}

.sidebar.uk-collapsed .nav > li:hover div ul a {
    padding-left: 20px;
}

.sidebar.uk-collapsed .nav > li:hover div ul a i {
    text-align: center;
    margin-right: 5px;
}

.sidebar.uk-collapsed .nav .uk-nav-header {
    display: block;
    color: #FE634A;
}


/* Page container */
.container {
    margin-left: 250px;
}

.uk-collapsed .container {
    margin-left: 50px;
}

.console {
    height: 180px;
    overflow: auto;
}

.console .padding {
    padding: 0px 0 20px 20px;
}

.padding-large-top {
    padding-top: 60px;
}


/* Datatables */

.no-sort::after {
    display: none!important;
}

.no-sort {
    pointer-events: none!important;
    cursor: default!important;
}

.dtable {
  display: none;
}

.dtable table tr {
    vertical-align: top;
}

/* SyntaxHighlighter overrides */

.syntaxhighlighter {
    padding: 10px 0;
}


/* Hide svg images until ready */

.svg-img {
    opacity: 0;
    visibility: hidden;
}

.svg-img.replaced-svg {
    opacity: 1;
    visibility: visible;
}


/* UiKit overrides */

a:hover {
    text-decoration: none
}

.uk-navbar-brand,
.uk-navbar-content,
.uk-navbar-toggle {
    text-shadow: none;
}

.uk-subnav-pill > .uk-active > * {
    color: #fff !important;
}

.uk-text-uppercase {
    text-transform: uppercase !important;
}

.uk-subnav.uk-subnav-center {
    display: block;
}

.uk-subnav.uk-subnav-center > * {
    float: none;
    display: inline-block;
}

em {
    color: inherit;
}

* {
    transition: all .2s ease-in-out;
}


/* Media queries */

@media only screen and (max-width: 768px) {
    .content-inner {
        margin-left: 0;
    }
}
