/* SASS Mixins */
/* Vertical align center everything */
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin preserve-3d {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

/* Text shadow */
@mixin textshadow($offset-y,$offset-x,$blur,$color,$opacity){
    text-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
}

  /* Opacity and colour */
  @mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
  }

@mixin box-shadow($offset-y,$offset-x,$blur,$color,$opacity) {
  -webkit-box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  -moz-box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  background-clip: padding-box;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

// Single side border-radius
@mixin border-topleft-radius($radius) {
   -webkit-border-top-left-radius: $radius;
   -moz-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-topright-radius($radius) {
   -webkit-border-top-right-radius: $radius;
   -moz-border-top-right-radius: $radius;
   border-top-right-radius: $radius;
   background-clip: padding-box;
}
@mixin border-bottomright-radius($radius) {
   -webkit-border-bottom-right-radius: $radius;
   -moz-border-bottom-right-radius: $radius;
   border-bottom-right-radius: $radius;
   background-clip: padding-box;
}
@mixin border-bottomleft-radius($radius) {
   -webkit-border-bottom-left-radius: $radius;
   -moz-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
